var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-layout-login-wraper" }, [
    _vm.displayMap.showRequest
      ? _c(
          "div",
          [
            _c("div", { staticClass: "text-h6 mb-xs" }, [
              _vm._v(" " + _vm._s(_vm.$t("Registration")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "mb-xs" },
              [
                _vm._v(" " + _vm._s(_vm.$t("Already have account?")) + " "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        path: "/user/login",
                        query: Object.assign({}, _vm.$route.query)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Sign in")))]
                )
              ],
              1
            ),
            _c(
              "a-form",
              { staticClass: "user-layout-login", attrs: { form: _vm.form } },
              [
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-input",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "email",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: _vm.$t("Please input your E-mail!")
                                  },
                                  {
                                    type: "email",
                                    transform: _vm.transform,
                                    message: _vm.$t(
                                      "The input is not valid E-mail!"
                                    )
                                  }
                                ],
                                validateFirst: true
                              }
                            ],
                            expression:
                              "[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('Please input your E-mail!')\n                },\n                {\n                  type: 'email',\n                  transform: transform,\n                  message: $t('The input is not valid E-mail!')\n                }\n              ],\n              validateFirst: true\n            }\n          ]"
                          }
                        ],
                        attrs: {
                          "allow-clear": "",
                          type: "text",
                          placeholder: _vm.$t("Enter your email")
                        }
                      },
                      [
                        _c("a-icon", {
                          style: { color: "rgba(0,0,0,.25)" },
                          attrs: { slot: "prefix", type: "user" },
                          slot: "prefix"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "primary",
                          "html-type": "submit",
                          block: "",
                          loading: _vm.loadding
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleSubmit($event)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Register")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.displayMap.showConfirm
      ? _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("div", { staticClass: "text-h6 q-my-md" }, [
            _vm._v(" " + _vm._s(_vm.$t("Please check your email.")) + " ")
          ]),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("We have sent registation form to your entered email")
                ) +
                " "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }