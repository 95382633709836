//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loadding: false,
      form: this.$form.createForm(this, { name: 'company_request' }),
      displayMap: {}
    };
  },
  computed: {
    email() {
      return this.form.getFieldValue('email');
    }
  },
  mounted() {
    this.updateDisplayMap('request');
  },
  methods: {
    transform(val) {
      return val && val.trim();
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (err, values) => {
        this.loadding = true;
        try {
          if (!err) {
            console.log(values);
            await this.$s.api.company.requestRegistration(values.email.trim());
            this.registerSuccess();
          }
        } catch (error) {
          console.log(error);
          const { errorCode } = error;
          if (errorCode === 'EMAIL_EXISTS') {
            this.form.setFields({
              email: {
                value: this.form.getFieldValue('email'),
                errors: [new Error(this.$tc('Email is already exists. Please use other email.'))]
              }
            });
          } else {
            this.form.setFields({
              email: {
                value: this.form.getFieldValue('email'),
                errors: [error]
              }
            });
          }
        }
        this.loadding = false;
      });
    },
    registerSuccess() {
      this.updateDisplayMap('comfirm');
    },
    updateDisplayMap(state) {
      this.displayMap = {
        showRequest: state === 'request',
        showConfirm: state === 'comfirm'
      };
    }
  }
};
